<template>
  <div class="mc-container">
    <div class="d-flex flex-column">
      <b-button 
        variant="primary mb-2 ml-auto"
        @click="generateOnlendingsByClinic"        
      >
        Gerar repasses médicos  
      </b-button>
    </div>
  </div>
</template>

<script>
import api from '@/modules/onlending/api.js'
export default {
name: 'Onlendings',
  props: {
    clinic: Object
  },
  methods: {
    async generateOnlendingsByClinic(){
      const loading = this.$loading.show();
      try {
        await api.generateOnlendingByClinicId(this.clinic.id)
        this.$toast.success(`Repasses faltantes gerados para a clinica ${this.clinic.name}!`)
      } catch ( err ){
        this.$toast.error(err.message)
      } finally {
        loading.hide()
      }
    }
  }
}
</script>

<style lang="scss">
.mc-container {
  min-height: 100vh;
  padding: 40px 20px;
}
</style>
